html {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Noto Sans KR', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  cursor: pointer;
}

button:focus {
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-responsive-modal-modal {
  max-width: 400px !important;
  padding: 30px !important;
  border-radius: 15px;
}

@media screen and (max-width: 960px) {
  .react-responsive-modal-modal {
    padding: 27px 30px !important;
    padding-bottom: 40px !important;
  }
}

@media screen and (max-width: 500px) {
  .react-responsive-modal-modal {
    padding: 25px 20px !important;
    padding-bottom: 40px !important;
  }
}

html[color-mode='dark'],
html[color-mode='dark'] body {
  background-color: #161920;
  color: white;
}

html[color-mode='dark'] .react-responsive-modal-modal {
  background-color: #212632;
}
